<script>
  import {EventBus} from '@/handler/event-bus'
  import { Bar, mixins } from 'vue-chartjs'
  const { reactiveProp } = mixins

  export default {
    extends: Bar,
    mixins: [reactiveProp],
    props: {
      chartData: {
        type: Object,
        default: {}
      },
      options: {
        type: Object,
        default: {}
      },      
    },
    mounted () {        
      this.render()
    },
    watch: {
      chartData: function() {
        this.$data._chart.update()
      },
    }, 
    methods: {
      render() {
        this.renderChart(this.chartData, this.options) 
      }
    }
  }
</script>
